<script setup lang="ts">
import {
  IconBtn,
  ContextMenu,
  ContextMenuItem,
  Icon,
} from 'webcc-ui-components'
import { debounce, clamp } from 'lodash-es'
import type { NavigationItem } from './Navigation.types'

const SHIFT_AMOUNT = 1

const props = defineProps<{
  allItems: NavigationItem[]
}>()

const eventBus = useEventBus<boolean>('guest-popup')

const hasYellowDot = useHasContractPriceYellowDot()

const gap = ref(8)
const current = ref(0)
const sliderShift = ref(0)
const widthOfAllItems = ref(0)
const itemsWidth = ref<number[]>([])
const viewWidth = ref(0)

const router = useRouter()
const route = useRoute()

const sliderWrapperRef = ref<HTMLDivElement>()
const sliderRef = ref<HTMLDivElement>()
const contextRefs = ref<InstanceType<any>[]>([])

const itemsLength = computed(() => {
  return props.allItems.length
})

/** Full width of slider, which is partially hidden */
const fullWidth = computed(() => {
  return widthOfAllItems.value + gap.value * (itemsLength.value - 1)
})

/** Max possible slider shift(margin-left) */
const maxShift = computed(() => fullWidth.value - viewWidth.value)
const isLeftButtonVisible = computed(() => current.value > 0)
const isRightButtonVisible = computed(() => sliderShift.value < maxShift.value)

watch(current, (nextItemIndex) => updateShift(nextItemIndex))

function cardSlideLeft() {
  current.value = clamp(current.value - SHIFT_AMOUNT, 0, itemsLength.value - 1)
}

function cardSlideRight() {
  current.value = clamp(current.value + SHIFT_AMOUNT, 0, itemsLength.value - 1)
}

/** Recalculating data for slider when resizing the window or clicking the arrows */
function updateShift(nextItemIndex: number) {
  updateWidths()

  current.value = clamp(nextItemIndex, 0, 100)

  const shiftWidth = itemsWidth.value
    .slice(0, current.value)
    .reduce((amount, currentValue) => {
      return amount + currentValue
    }, 0)

  sliderShift.value = clamp(shiftWidth, 0, maxShift.value)
}

function updateWidths() {
  widthOfAllItems.value = 0

  for (let i = 0; i < itemsLength.value; i++) {
    itemsWidth.value[i] =
      (sliderRef.value?.children[i] as HTMLElement)?.offsetWidth || 0
    widthOfAllItems.value += itemsWidth.value[i]
  }

  if (sliderWrapperRef.value) {
    viewWidth.value = sliderWrapperRef.value?.offsetWidth
  }
}

function isSelected(item: NavigationItem) {
  if (typeof item.value === 'string') {
    return item.routeName === route.name
  } else {
    const routeNames = item.value.map(({ routeName }) => routeName)
    return routeNames.includes(route.name as string)
  }
}

function handleClick(item: NavigationItem, index?: number) {
  if (item.routeName === route.name) {
    typeof index === 'number' && closeMenu(index)
    return
  }

  if (item.value === 'open-guests-data') {
    eventBus.emit(true)
    typeof index === 'number' && closeMenu(index)
  } else {
    router.push(item.value as string)
    typeof index === 'number' && closeMenu(index)
  }
}

/** Closing dropdown submenu, when clicking on submenu item */
function closeMenu(index: number) {
  const element = contextRefs.value?.[index]
  if (!element) return
  element.close()
}

const updateSliderProperties = debounce(() => updateShift(current.value), 150)

onMounted(() => {
  // debounce updating slider properties on screen size change
  useEventListener('resize', updateSliderProperties)

  // call update slider sizes and buttons on next frame
  requestAnimationFrame(updateSliderProperties)
})

onBeforeUnmount(() => {
  updateSliderProperties?.cancel()
})
</script>

<template>
  <div data-id="navigation-slider" class="relative">
    <ClientOnly>
      <div
        v-show="isLeftButtonVisible"
        class="leftButton gradient-scroll-left absolute top-1/2 left-4 z-10 flex h-full items-center pr-4"
      >
        <IconBtn icon="arrow-left" size="lg" @click="cardSlideLeft" />
      </div>
    </ClientOnly>

    <div ref="sliderWrapperRef" class="slider-wrapper relative">
      <div
        ref="sliderRef"
        class="slider flex gap-2"
        :style="`margin-left: -${sliderShift}px`"
      >
        <div
          v-for="(item, index) in allItems"
          v-show="!item.hide"
          :key="index"
          class="flex items-center"
        >
          <div
            v-if="Array.isArray(item.value)"
            class="border border-transparent"
          >
            <ContextMenu
              :ref="(r: any) => (contextRefs[index] = r)"
              :key="`mainMenuItem${index}`"
              :offset="0"
              no-transition
              placement="bottom-start"
            >
              <template #activator>
                <div class="flex gap-x-2 justify-center items-center">
                  <button
                    :class="{
                      'bookings-button':
                        item.label === $t('components.common.bookings'),
                      'bg-thm-active !text-white hover:border hover:border-thm hover:bg-white hover:!text-txt-link':
                        isSelected(item),
                    }"
                    class="flex items-center rounded border border-transparent py-3 px-2 text-txt-400 hover:border-thm hover:text-txt-link"
                  >
                    <ClientOnly>
                      <BasicDot
                        v-if="
                          hasYellowDot &&
                          item.label === $t('components.bookings.properties')
                        "
                        size="xs"
                        class="mr-2"
                      />
                    </ClientOnly>

                    <span class="text-base font-medium">
                      {{ item.label }}
                    </span>

                    <Icon
                      graphic="arrow-down"
                      size="sm"
                      class="md:ml-2 lg:ml-4"
                    />
                  </button>
                </div>
              </template>

              <ContextMenuItem
                v-for="(contextMenuItem, contextMenuIndex) in item.value"
                v-show="!contextMenuItem.hide"
                :key="`${index}-${contextMenuIndex}`"
                :class="{
                  'context-item-active bg-thm-light hover:bg-white':
                    isSelected(contextMenuItem),
                }"
                @click="handleClick(contextMenuItem, index)"
              >
                <template #prefix>
                  <ClientOnly>
                    <BasicDot
                      v-if="
                        hasYellowDot &&
                        contextMenuItem.label ===
                          $t('components.contractPrices.title')
                      "
                      size="xs"
                      class="mr-2"
                    />
                  </ClientOnly>
                </template>

                <span class="flex-1 text-txt-300">
                  {{ contextMenuItem.label }}
                </span>
              </ContextMenuItem>
            </ContextMenu>
          </div>

          <div
            v-else
            class="home-button relative flex cursor-pointer items-center rounded border border-transparent py-3 px-2 text-txt-400 hover:border-thm hover:text-txt-link"
            :class="{
              'bg-thm-active text-white hover:border hover:border-thm hover:bg-white hover:text-txt-link':
                isSelected(item),
            }"
            @click="handleClick(item)"
          >
            <span class="text-base font-medium">
              {{ item.label }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <ClientOnly>
      <div
        v-show="isRightButtonVisible"
        class="rightButton gradient-scroll-right absolute top-1/2 right-0 z-10 flex h-full items-center pl-4"
      >
        <IconBtn icon="arrow-right" size="lg" @click="cardSlideRight" />
      </div>
    </ClientOnly>
  </div>
</template>

<style scoped>
.slider-wrapper {
  position: relative;
  overflow: hidden;
}

.context-item-active > span {
  color: theme('colors.thm.DEFAULT');
}

.context-item-active:hover > span {
  color: inherit;
}

.slider {
  transition: 0.15s;
}

.slider > :deep(*) {
  flex-shrink: 0;
}

.rightButton {
  transform: translateY(-50%);
}

.leftButton {
  transform: translate(-50%, -50%);
}

.gradient-scroll-left {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gradient-scroll-right {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>

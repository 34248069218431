<script setup lang="ts">
import type { NavigationItem } from './Navigation.types'
import { ANNUAL_REPORT_ANCHOR } from '~/components/payment/constants'

const auth = useAuth()
const { t } = useI18n()
const { $ROUTE, $unleash } = useNuxtApp()

const showGuestsData = computed(
  () => auth.profile.present && auth.profile.permissions.DownloadTravellerData,
)

const mainMenuItems = computed<NavigationItem[]>(() => [
  {
    routeName: 'index',
    label: t('components.common.home'),
    value: $ROUTE.INDEX,
  },
  {
    label: t('components.common.bookings'),
    value: [
      {
        routeName: 'reservations-type',
        label: t('components.bookings.arrivalsAndDepartures'),
        value: $ROUTE.RESERVATIONS_ARRIVALS,
      },
      {
        routeName: 'bookings-calendar',
        label: t('components.navigation.calendar'),
        value: $ROUTE.BOOKINGS_CALENDAR,
        hide: !auth.profile.permissions?.ViewBookingCalendar,
      },
      {
        label: t('components.navigation.guestsData'),
        value: 'open-guests-data',
        hide: !showGuestsData.value,
      },
    ],
  },
  {
    label: t('components.navigation.payments'),
    hide: !auth.profile.permissions?.ViewPayments,
    value: [
      {
        routeName: 'payments-overview',
        label: t('components.common.paymentsOverview'),
        value: $ROUTE.PAYMENTS_OVERVIEW,
      },
      {
        routeName: 'payments-overview-statements',
        label: t('components.common.statements'),
        value: $ROUTE.PAYMENTS_STATEMENTS,
      },
      {
        label: t('components.navigation.taxReturn'),
        value: `${$ROUTE.PAYMENTS_OVERVIEW}#${ANNUAL_REPORT_ANCHOR}`,
      },
    ],
  },

  {
    label: t('components.bookings.properties'),
    value: [
      {
        routeName: 'contract-prices-slug',
        label: t('components.contractPrices.title'),
        value: $ROUTE.CONTRACT_PRICES,
        hide:
          !$unleash.features['contract-prices'] ||
          !auth.profile.permissions?.ViewContractPrices,
      },
      {
        routeName: 'property-benchmark-slug',
        label: t('components.common.propertyBenchmark'),
        value: $ROUTE.PROPERTY_BENCHMARK,
        hide:
          !$unleash.features['property-benchmark'] ||
          !auth.profile.permissions?.ViewPropertyBenchmark,
      },
      {
        routeName: 'reviews',
        label: t('components.common.guestsReviews'),
        value: $ROUTE.REVIEWS,
      },
    ],
  },
  {
    routeName: 'opportunities',
    label: t('components.navigation.opportunities'),
    value: $ROUTE.OPPORTUNITIES,
    hide:
      !$unleash.features.opportunities ||
      !auth.profile.permissions?.ViewOpportunities,
  },
  {
    routeName: 'inbox',
    label: t('components.common.inbox'),
    value: $ROUTE.INBOX,
  },
])
</script>

<template>
  <NavigationSlider :all-items="mainMenuItems" />
</template>

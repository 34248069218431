<script setup lang="ts">
import { Icon, ContextMenu, ContextMenuItem } from 'webcc-ui-components'

type NavBar = {
  label: string
  routeNames: string[]
  action: () => any
  dataId?: string
  hide: boolean
}

const { t } = useI18n()
const { $auth, $ROUTE, $unleash } = useNuxtApp()
const { trackMyProfileClick, trackLogOutClick } = useHeaderTracking()

const contextMenuRef = ref<InstanceType<typeof ContextMenu>>()

const { data: accommodations } = await useTryCatchSuspense(
  useAccommodationsQuery,
)

const contractAvailableAccommodationCode = computed(() => {
  if (
    $unleash.features['document-downloads'] &&
    $auth.profile.permissions?.ViewDocumentDownloads
  ) {
    const availableAccommodations =
      accommodations.value?.filter(
        (accommodation) => accommodation.contractPdfAvailable,
      ) ?? []

    return availableAccommodations[0]?.accommodationCode
  }
  return undefined
})

const profileMenuItems = computed(
  () =>
    [
      {
        label: t('components.navigation.profile'),
        action: () => {
          trackMyProfileClick()
          navigateTo($ROUTE.PROFILE)
        },
        routeNames: [
          'profile',
          'profile-change-address',
          'profile-change-phone',
          'profile-change-password',
          'profile-payment-info',
          'profile-notifications',
        ],
      },
      {
        label: t('components.document.downloads'),
        action: () =>
          navigateTo(
            `${$ROUTE.DOCUMENT_DOWNLOADS}/${contractAvailableAccommodationCode.value}`,
          ),
        hide: !contractAvailableAccommodationCode.value,
        routeNames: ['document-downloads-accommodationCode'],
      },
      {
        dataId: 'navigation-logout',
        label: t('components.common.logout'),
        action: () => {
          trackLogOutClick()
          $auth.logout()
        },
        routeNames: [],
      },
    ] as NavBar[],
)

const visibleProfileMenuItems = computed(() =>
  profileMenuItems.value.filter((item) => !item.hide),
)

const ownerNumber = computed(() =>
  t('components.navigation.ownerNumber', {
    ownerNumber: $auth.profile.userID ?? '',
  }),
)

function handleContextMenuItemClick(item: NavBar) {
  item.action?.()
  contextMenuRef.value?.close()
}
</script>

<template>
  <nav data-id="navigation-bar" class="z-[1] bg-bgr">
    <div class="py-3 shadow-base">
      <div
        class="mx-auto flex w-full max-w-[1270px] items-center justify-between gap-x-4 px-4 md:px-6 lg:px-8 2xl:px-0"
      >
        <div class="min-w-0 grow">
          <NavigationDesktopMenu />
        </div>

        <div class="flex items-center gap-x-4 ml-auto">
          <BasicOldOwnerPortalButton
            class="inline-block overflow-hidden whitespace-nowrap clear-both"
          />

          <div class="border border-transparent">
            <ContextMenu
              ref="contextMenuRef"
              no-transition
              :offset="0"
              placement="bottom-end"
            >
              <template #activator>
                <button
                  type="button"
                  class="flex cursor-pointer items-center space-x-4 rounded border border-transparent py-3 px-2 text-txt-400 hover:border-thm hover:text-txt-link"
                  data-id="navigation-dropdown-btn"
                >
                  <Icon graphic="account-circle" size="md" />
                  <Icon graphic="arrow-down" size="sm" />
                </button>
              </template>

              <ContextMenuItem
                key="ownerNumber"
                class="cursor-auto bg-bgr-100 [&&]:justify-start"
              >
                <span class="text-base font-normal italic text-txt-500">
                  {{ ownerNumber }}
                </span>
              </ContextMenuItem>

              <ContextMenuItem
                v-for="(item, index) in visibleProfileMenuItems"
                :key="`profileMenuItem-${index}`"
                :class="{
                  'context-item-active bg-thm-light hover:bg-white':
                    item.routeNames.includes($route.name as string),
                }"
                @click="handleContextMenuItemClick(item)"
              >
                <span class="flex-1 text-txt-300" :data-id="item.dataId">
                  {{ item.label }}
                </span>
              </ContextMenuItem>
            </ContextMenu>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.context-item-active > span {
  color: theme('colors.thm.DEFAULT');
}

.context-item-active:hover > span {
  color: inherit;
}
</style>
